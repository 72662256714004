
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































































































/* stylelint-disable max-nesting-depth, declaration-no-important */

.flexible-promo {
  @include mq($until: m) {
    max-width: none !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.flexible-promo-inner {
  position: relative;
  display: flex;
  background: $c-mustard;
  transform: translateX(0); // for link::after

  .flexible-promo.wrapper & {
    background: none;
  }
}

.flexible-promo__panel {
  @extend %ff-alt;
  @extend %text-center;

  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding: $spacing * 0.75;
  color: $c-white;
  background: $c-mustard;

  @include mq($until: s) {
    width: min-content;
    min-width: 40%;

    .has-experience & {
      padding-bottom: $spacing * 2;
    }
  }

  @include mq($until: xs) {
    width: 50%;
  }

  @include mq(s) {
    width: col(4, 10);
    padding: $spacing $spacing * 2;
  }

  @include mq(m) {
    .flexible-promo.wrapper & {
      border-radius: 0.8rem 0 0 0.8rem;
    }
  }

  @include mq(wrapper) {
    .full & {
      width: 54rem;
      margin-left: calc(calc(100vw - #{$content-width}) * 0.5);
    }
  }
}

.flexible-promo__panel__picto {
  @extend %fw-bold;
  @include fluid(
    font-size,
    (
      xxs: 32px,
      xxl: 48px,
    )
  );
  @include fluid(
    line-height,
    (
      xxs: 60px,
      xxl: 95px,
    )
  );
  @include fluid(
    width,
    (
      xxs: 60px,
      xxl: 95px,
    )
  );

  margin: 0 auto $spacing;
  color: $c-mustard;
  background: $c-white;
  border-radius: 50%;
}

.flexible-promo__panel__headline {
  @extend %text-uppercase;
  @include fluid(
    font-size,
    (
      xxs: 10px,
      xxl: 25px,
    )
  );
}

.flexible-promo__panel__title {
  @extend %fw-medium;
  @include fluid(
    font-size,
    (
      xs: 18px,
      s: 22px,
      xxl: 60px,
    )
  );
  @include fluid(
    line-height,
    (
      xs: 20px,
      s: 23px,
      xxl: 60px,
    )
  );
}

.flexible-promo__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/9;

  @include mq(s) {
    flex: 1;

    .flexible-promo.wrapper & {
      aspect-ratio: 3/2;
    }
  }
}

[class*='flexible-promo__picture__link--'] {
  position: fixed;
  z-index: 2;
  text-decoration: none;

  &::after {
    @include get-all-space;

    content: '';
    position: fixed;
  }

  @include mq(wrapper) {
    .flexible-promo.full & {
      // sticks to the border of the wrapper (12rem = 1 col)
      right: calc(calc(calc(100% - #{$content-width}) * 0.5) + 12rem);
    }
  }
}

.flexible-promo__picture__link--card {
  right: $spacing * 1.5;
  bottom: $spacing * 1.5;
  width: fit-content;
  max-width: 36rem;

  @include mq($until: s) {
    $margin: 3rem;

    top: calc(100% - 2rem);
    bottom: initial;
    left: $margin;
    width: calc(100% - #{$margin * 2});
    max-width: none;
  }

  @include mq($from: xs, $until: s) {
    $margin: 8rem;

    left: $margin;
    width: calc(100% - #{$margin * 2});
  }
}

.flexible-promo__picture__link-inner {
  width: 100%;
  padding: $spacing;
  background: $c-white;
  border-radius: 0.6rem;

  @include mq($until: s) {
    padding: $spacing * 0.5;
    background: rgba($c-white, 80%);
    border: 0.1rem solid $c-white;
    backdrop-filter: blur(1rem);
  }

  @include mq(l) {
    padding: $spacing * 1.5;
  }
}

.flexible-promo__picture__link__label {
  @extend %ff-alt;
  @extend %fw-bold;

  ::v-deep em,
  ::v-deep i {
    @extend %ff-default;
    @extend %fw-thin;

    font-style: italic;
    line-height: 1.1;
  }

  @include mq(l) {
    font-size: 2rem;
    line-height: 1.25;
  }
}

.flexible-promo__picture__link__icon {
  color: $c-mustard;
  transition: transform 0.3s $ease-softer;

  [class*='flexible-promo__picture__link--']:hover &,
  [class*='flexible-promo__picture__link--']:focus-visible & {
    transform: translate(1rem);
  }
}

.flexible-promo__picture__link--button {
  right: $spacing * 0.5;
  bottom: $spacing * 0.5;

  &::after {
    @include get-all-space;

    content: '';
    position: fixed;
  }

  ::v-deep .action__icon-wrapper {
    @include mq($until: s) {
      display: none;
    }
  }

  @include mq($until: s) {
    &[class*='action--'][class*='--btn'] {
      padding: $spacing * 0.25 $spacing * 0.5;
    }
  }

  @include mq(s) {
    right: $spacing * 1.5;
    bottom: $spacing * 1.5;
  }

  @include mq(l) {
    &[class*='action--'][class*='--btn']:hover,
    &[class*='action--'][class*='--btn']:focus-visible {
      background: $c-white;
    }
  }

  @include mq(wrapper) {
    .flexible-promo.full & {
      bottom: $spacing * 3;
    }
  }
}

.flexible-promo__picture__img,
.flexible-promo__picture__video {
  @include get-all-space;

  overflow: hidden;

  ::v-deep img,
  ::v-deep video {
    transition: transform 0.3s $ease-softer;
  }

  ::v-deep [class*='flexible-promo__picture__link--']:hover ~ &,
  ::v-deep [class*='flexible-promo__picture__link--']:focus-visible ~ & {
    /* stylelint-disable-next-line */
    img,
    video {
      transform: scale(1.05);
    }
  }

  @include mq(m) {
    .flexible-promo.wrapper & {
      border-radius: 0 0.8rem 0.8rem 0;
      transform: translateX(0); // Safari fix
    }
  }
}
