
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































































































.video-loop {
  position: relative;
  overflow: hidden;
}

.video-loop__video {
  width: 100%;

  .cover & {
    @include image-fit;
  }
}

.video-loop__picture {
  @include get-all-space;

  z-index: 1;
}
